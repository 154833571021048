// required to get $orange variable
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

$primary: #3e97ff; // set the $primary variable
$card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
$card-border-radius: 0.625rem;

// merge with existing $theme-colors map
$theme-colors: map-merge(
    $theme-colors,
    (
        "primary": $primary
    )
);

@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;700&display=swap");

body,
html {
    margin: 0;
    font-family:
        "Poppins",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.fs {
    &-14 {
        font-size: 14px;
    }

    &-12 {
        font-size: 12px;
    }
}

.my-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
}

@media (min-width: 1200px) {
    .fs-3x {
        font-size: 3rem !important;
    }
}

.text-gray-400 {
    color: $gray-400 !important;
}

.text-cs-gray-400 {
    color: #b5b5d3;
}

.text-gray-800 {
    color: $gray-800 !important;
}

// auth page css
.auth-container {
    &__bg {
        // background-image: url("http://localhost:3003/assets/bg-image.jpeg");
        background-size: cover;
        height: 100vh;
    }

    form {
        input {
            border: none !important;
            font-size: 14px !important;
            outline: none !important;

            &:focus,
            &:active,
            &:focus-within {
                outline: none !important;
                border: none;
                box-shadow: none;
            }
        }
    }

    .forgot-btn {
        color: rgba(gray, 0.75);
        text-decoration: none !important;

        &:hover {
            color: $link-color;
        }
    }

    .signIn-btn {
        font-size: 14px;
    }

    @media screen and (max-width: 768px) {
        &__bg {
            padding: 48px 0 !important;
            height: auto;
        }
    }
}

// end of auth page css

// header css
header {
    font-family: Inter, Helvetica, sans-serif;
    background: white;
    padding: 24px 0;
    box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.5);

    ul {
        margin-bottom: 0;

        li {
            font-size: 14px;
            margin: 0 8px;

            a,
            button {
                font-size: 14px !important;
                font-weight: 600 !important;
                color: #4b5675 !important;
                text-decoration: none;
                transition: color 0.2s ease;
                padding: 12px;

                &.active {
                    background-color: transparent;
                    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.03);
                    color: $primary !important;
                }
            }
        }
    }
}

.dashboard-container {
    font-family: Inter, Helvetica, sans-serif;

    // dashboard
    .home-top-card {
        border: 1px solid #f1f1f2;
        box-shadow: $card-box-shadow !important;
        border-radius: $card-border-radius;

        .card-body {
            padding: 2rem 2.25rem !important;
        }

        .card-title {
            color: #071437 !important;
        }

        &.sm {
            .card-body {
                padding: 1.75rem 1.75rem !important;
            }
        }
    }

    .stats-list {
        li {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;

            &:not(:last-child) {
                border-bottom: 1px dashed #dbdfe9;
            }
        }
    }

    // chat css
    .chatBot--messages {
        padding: 8px 16px;

        ul {
            padding-left: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                width: inherit;

                img {
                    width: 32px;
                    height: 32px;
                    padding: 4px 3px 4px 4px;
                    position: relative;
                    top: -4px;
                    align-self: flex-start;
                    border-radius: 50%;
                    margin-right: 6px;
                    background-color: rgba(128, 128, 128, 0.2);
                }

                span {
                    font-size: 14px;
                    position: relative;
                    min-height: 32px;
                    word-break: break-word;
                    box-sizing: border-box;
                    border-style: solid;
                    border-width: 0px;
                    border-color: rgb(203, 214, 226);
                    background-color: rgb(234, 240, 246);
                    color: rgb(66, 91, 118);
                    display: inline-flex;
                    align-self: flex-start;
                    align-items: center;
                    padding: 12px;
                    border-radius: 0px 8px 8px;
                    margin-bottom: 12px;
                    animation: opacityTranition 200ms ease-in-out;
                }

                &.user {
                    align-self: flex-end;

                    span {
                        align-self: flex-end;
                        color: white;
                        background-color: $primary;
                        border-radius: 8px 0px 8px 8px;
                    }
                }
            }
        }
    }

    .pagination li {
        cursor: pointer;
    }

    .qna-Form {
        .remove-icon {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 10px;
        }

        .add-more {
            width: 100%;

            button {
                font-size: 14px;
                width: 100%;
                border: 1px dashed gray;
            }
        }
    }
}

.loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000 !important;
    width: 100vw;
    height: 100vh;

    &--loading {
        border: 8px solid #f3f3f3;
        /* Light grey */
        border-top: 8px solid $dark;
        /* Blue */
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 2s linear infinite;
    }

    &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.2);
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.badge-light-success {
    color: #50cd89;
    background-color: #e8fff3;
}
.badge-light-danger {
    color: #f1416c;
    background-color: #fff5f8;
}

.badge {
    display: inline-flex;
    align-items: center;
}
.fs-base {
    font-size: 1rem !important;
}

.w-30px {
    width: 30px !important;
}

.btn-primary {
    font-weight: 500;
}
.btn.btn-secondary {
    color: #4b5675;
    box-shadow: none;
    border: 0;
    background-color: $gray-200;
}

.accordion {
    &-header {
        border: unset !important;
        border-radius: 0.375rem !important;
    }
    &-button {
        &::after {
            content: unset;
        }
        background-color: white !important;
        border: unset !important;
        font-weight: 500;
        border-radius: 0.375rem !important;
        outline: none !important;

        &::before {
            margin-right: 14px;
            content: "+";
            color: gray;
            line-height: 1.2;
            font-size: 16px;
            border-radius: 8px;
            padding: 0px 4px 1px;
            border: 1px solid $gray-300;
            background-color: $gray-200;
        }

        &[aria-expanded="true"] {
            border-radius: 0.375rem 0.375rem 0 0 !important;
            &::before {
                content: "-";
                padding: 0px 6px 1px;
            }
        }
    }

    &-item {
        border: unset !important;
        margin-bottom: 20px;
        box-shadow: $card-box-shadow;
        border-radius: 0.375rem !important;
    }
}

#chatSessionModal {
    .modal-content {
        min-height: 400px !important;
    }
}

.card-footer {
    border-top: 1px solid $gray-200 !important;
}

.pagination {
    .page-item {
        margin: 0 4px;
        .page-link {
            color: $gray-700;
            border: unset;
            padding: 4.875px 9.75px !important;
            font-size: 13.975px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.475rem;
            height: 2rem;
            min-width: 2rem;
            font-weight: 500;
            background-color: transparent !important;
            box-shadow: unset;
            outline: none;
        }

        &.active {
            .page-link {
                color: $white;
                background-color: $primary !important;
            }
        }
    }
}

.separator {
    position: relative;
    width: 100%;
    text-align: center;
    color: $gray-400;
    font-size: 14px;

    &::before {
        content: "";
        position: absolute;
        left: 2%;
        top: 50%;
        width: 46%;
        height: 1px;
        background-color: $gray-300;
    }

    &::after {
        content: "";
        position: absolute;
        right: 2%;
        top: 50%;
        width: 46%;
        height: 1px;
        background-color: $gray-300;
    }
}
